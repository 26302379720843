import { ListOfTitles, toImageProps } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TListOfTitlesConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'list-of-titles' }
>;

const ListOfTitlesConnected: React.FC<TListOfTitlesConnectedProps> = props => {
  const { list } = props;

  return (
    <ListOfTitles
      list={list.map(item => ({
        logo: toImageProps(item?.logo),
        title: item.title,
      }))}
    />
  );
};

export default ListOfTitlesConnected;
